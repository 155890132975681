export default {
  setProductData(state, data) {
    state.productList = data;
  },
  setAttributes(state, data) {
    state.attributes = data;
  },
  updateCustomAttribute(state, value){
    const instanceId = value.instanceId
    const selectedValues = value.value.toString()
    console.log(instanceId, selectedValues)
    const getIndex = state.product.custom_attributes?.findIndex(
      (attribute) => attribute.attribute_code === instanceId,
    );
    state.product.custom_attributes[getIndex].value = selectedValues;
  },
  setCustomAttributes(state, data) {
    let custom_attributes_server = data.custom_attributes;
    if (custom_attributes_server?.length) {
      custom_attributes_server = data.custom_attributes;
    }
    state.whitelist.forEach((item) => {
      if (custom_attributes_server.find((attr) => attr.attribute_code === item)){
        return
      } else {
        custom_attributes_server.push({ attribute_code: item, value: '' });
      }
    })
      state.custom_attributes = custom_attributes_server;
  },
  setNationalCode(state, value) {
    state.national_code = value?.length ? value[0]?.national_code : value?.national_code;
  },
  setNationalCodeFromProduct(state) {
    let custom_attributes = state.product?.custom_attributes;
    if (custom_attributes?.length) {
      let nationalData = custom_attributes.find((attr) => attr.attribute_code === 'national_code');
      state.national_code = nationalData?.value;
    }
  },
  setNationalCodeToProduct(state) {
    let custom_attributes = state.product?.custom_attributes;
    if (custom_attributes?.length) {
      custom_attributes = custom_attributes.filter((attr) => attr.attribute_code !== 'national_code');
      custom_attributes.push({ attribute_code: 'national_code', value: state.national_code });
    }
    state.product.custom_attributes = custom_attributes;
    console.log(state.product, 'product');
  },
  setProduct(state, data) {
    state.product = data;
  },
  setChildProducts(state, data) {
    state.childProducts = data;
  },
  setAttributeOptions(state, value) {
    state.attributeOptions = value;
  },
}
