import Vue from 'vue'

Vue.component('card', require('../components/cards/card').default)
Vue.component('Progressbar', require('../components/progressbar/Progressbar').default)
Vue.component('Slick', require('../components/slider/Slick').default)
Vue.component('tab-nav', require('../components/tab/tab-nav').default)
Vue.component('tab-nav-items', require('../components/tab/tab-nav-items').default)
Vue.component('tab-content', require('../components/tab/tab-content').default)
Vue.component('tab-content-item', require('../components/tab/tab-content-item').default)
Vue.component('ModeSwitch', require('../components/mode/ModeSwitch').default)
Vue.component('Select2', require('../components/form/select2/Select2').default)
Vue.component('VueSelectPicker', require('../components/form/selectpicker/VueSelectpicker').default)
Vue.component('tableList', require('../components/table/tablelist').default)
Vue.component('titlesectionList', require('../components/titlesection/titlesectionlist').default)
