import { DataService } from '../../config/dataService';
import cookies from 'js-cookie';
export default {
  async fetchInvoiceList (context) {
    try {
      const activeStoreView = cookies.get('active_store_view');
      let {
        data: { data, status },
      } = await DataService.get(`${activeStoreView}/invoices`);
      if (status === 'success') {
        context.commit('setInvoiceList', data);
      }
    } catch ({ data }) {
        context.commit('setInvoiceList', 'test');
    }
  },
  async fetchItemsToInvoice({ commit }, { entityId }) {
    try {
      const activeStoreView = cookies.get('active_store_view');
      let {
        data: { data, status },
      } = await DataService.get(`${activeStoreView}/orders/${entityId}`);
      if (status === 'success') {
        await commit('setAvailableItems', data.items);
      }
    } catch ({ data }) {
      commit('setError', data.error);
    }
  },
}
