export default {
  setInvoiceList (state, data) {
    state.invoices.push(data)
  },
  setInvoice (state, data) {
    state.invoice.push(data)
  },
  setAvailableItems (state, data) {
    data.forEach(function (item) {
      item.qty_to_invoice = item.qty_ordered;
      item.qty_to_ship = item.qty_ordered;
      item.courier = '';
      item.tracking_no = '';
      item.tracking_name = '';
    });
    state.newInvoice = data
  },
  setNewShipment (state, data) {
    data.forEach(function (item) {
      item.qty_to_ship = item.qty_invoiced;
    });
    state.newShipment = data
  }
}
