<template>
  <router-view/>
</template>
<script>
// import 'line-awesome/dist/line-awesome/css/line-awesome.min.css'
// import 'remixicon/fonts/remixicon.css'

export default {
  name: 'App',
  components: {
  },
  mounted () {
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  @import "./assets/scss/backend.scss";
  @import "./assets/css/custom.css";
</style>
