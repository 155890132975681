export const initialFilters = {
    increment_id: '',
    created_at: {
      from: null,
      to: null,
    },
    status: '',
    customer_email: '',
    location_id: '',
  };
  
  export default {
    fetchBegin(state) {
      state.loading = true;
    },
    fetchEnd(state) {
      state.loading = false;
    },
    startSaving(state) {
      state.saving = true;
    },
    endSaving(state) {
      state.saving = false;
    },
    fetchOrdersSuccess(state, { items, total_count }) {
      state.orders = items;
      state.loading = false;
      state.saving = false;
      state.totalCount = total_count;
    },
    fetchOpenCartsSuccess(state, { items, total_count }) {
      state.openCarts = items ? items : [];
      state.loading = false;
      state.saving = false;
      state.totalCount = total_count;
    },
    fetchOrderSuccess(state, data) {
      state.order = data;
      state.saving = false;
    },
    fetchShipmentsSuccess(state, { items }) {
      state.shipments = items;
    },
    fetchInvoicesSuccess(state, { items }) {
      state.invoices = items;
    },
    fetchRefundsSuccess(state, { items }) {
      state.refunds = items;
    },
    createShipmentTrackSuccess(state) {
      state.saving = false;
    },
  
    setShipment(state, shipmentId) {
      state.shipment = state.shipments.length && state.shipments.find((shipment) => shipment.entity_id == shipmentId);
    },
    setInvoice(state, invoiceId) {
      state.invoice = state.invoices.length && state.invoices.find((invoice) => invoice.entity_id == invoiceId);
    },
    setRefund(state, refundId) {
      state.refund = state.refunds.length && state.refunds.find((refund) => refund.entity_id == refundId);
    },
  
    setOrderFilters(state, filters) {
      state.filters = Object.assign({}, JSON.parse(JSON.stringify(filters)));
    },
    clearOrderFilters(state) {
      state.filters = { ...initialFilters };
    },
  
    setThirdPartyPaymentInfo(state, payload) {
      state.thirdPartyPaymentInfo = payload;
    },
  
    setError(state, err) {
      state.loading = false;
      if (err) state.error = 'There was an error processing this request';
      state.saving = false;
    },
  
    setSearchKey(state, sku) {
      state.searchKey = sku;
    },
  };
  