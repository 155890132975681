import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  invoices: [],
  invoice: [],
  newInvoice: {},
  trackingInfo: [
    {
    courier: '',
    tracking_no: '',
    tacking_name: '',
    }
  ]
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
