import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  availableFilters: [
    {
      id: 1,
      name: 'SKU',
      field: 'sku',
      operators: [
          'like',
          'eq'
      ],
      component: 'text',
      isMultiSelect: false,
      filterValue: '',
      isActive: false
    }
  ]
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
