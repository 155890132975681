import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie';
import { ACCESS_TOKEN } from '@/config/cookieKeys';
import store from '../store'
//Adding layouts router.
const BlankLayout = () => import("@/layouts/BlankLayout")
const Layout1 = () => import("@/layouts/backend/Layout-1")
const LayoutSubMenu = () => import("@/layouts/backend/Layout-submenu")
const ProductLayout = () => import("@/layouts/backend/product")

const home = () =>import('@/views/backend/home')

//auth elements
const SignIn = () => import('@/views/backend/Auth/SignIn')
const Reset = () => import('@/views/backend/Auth/RecoverPassword')
const ResetToken = () => import('@/views/backend/Auth/ResetPassword')

//pages elements
const Error404 = () => import('@/views/backend/Pages/Error/Error404')
const Error500 = () => import('@/views/backend/Pages/Error/Error500')
const Maintainance = () => import('@/views/backend/Pages/Maintainance')

const OrderList = () =>import('@/views/backend/Orders/allorders')
const OrderSummary = () =>import('@/views/backend/Orders/order-summary')
const OrderInvoiceList = () =>import('@/views/backend/Orders/order-invoicelist')
const OrderFulfilmentList = () =>import('@/views/backend/Orders/order-fulfilmentlist')
const OrderStrpe = () =>import('@/views/backend/Orders/payments/stripe')

const ProductList = () =>import('@/views/backend/product/listproduct')

const ProductSummary = () =>import('@/views/backend/Products/product-summary')
const ProductFitment = () =>import('@/views/backend/Products/product-fitment')
const ProductMedia = () =>import('@/views/backend/Products/product-media')


Vue.use(VueRouter)
const orders = () => [
  {
    path: '/',
    name: 'allorders',
    meta: {  name: 'All Orders', requiresAuth: true},
    component: OrderList
  },
]
const products = () => [
  {
    path: '/',
    name: 'allproducts',
    meta: {  name: 'All Products', requiresAuth: true},
    component: ProductList
  },
]
const subMenuRequired = () => [
  {
    path: ':id/:encryptkey/:number/overview',
    name: 'order.summary',
    meta: {  name: 'Order Summary', requiresAuth: true, checkSHA: true},
    component: OrderSummary
  },
  {
    path: ':id/:encryptkey/:number/invoices',
    name: 'order.OrderInvoiceList',
    meta: {  name: 'Order Invoices', requiresAuth: true},
    component: OrderInvoiceList
  },
  {
    path: ':id/:encryptkey/:number/fulfilments',
    name: 'order.FulfilmentList',
    meta: {  name: 'Order Fulfulments', requiresAuth: true},
    component: OrderFulfilmentList
  },
  {
    path: ':id/:encryptkey/:number/payment-stripe',
    name: 'order.Stripe',
    meta: {  name: 'Stripe Logs', requiresAuth: true},
    component: OrderStrpe
  }
]
const productMenuRequired = () => [
  {
    path: ':sku/overview',
    name: 'product.Summary',
    meta: {  name: 'Product Summary', requiresAuth: true},
    component: ProductSummary
  },
  {
    path: ':sku/media',
    name: 'product.Media',
    meta: {  name: 'Product Media', requiresAuth: true},
    component: ProductMedia
  },
  {
    path: '/:id/fitment',
    name: 'product.Fitment',
    meta: {  name: 'Product Fitment', requiresAuth: true},
    component: ProductFitment
  }
]
const authchildRoute = () =>[
  {
    path: 'sign-in',
    name: 'auth.login',
    meta: {  name: 'SignIn' },
    component: SignIn
  },
  {
    path: 'reset',
    name: 'auth.reset',
    meta: {  name: 'Reset' },
    component: Reset
  },
  {
    path: 'reset/:token',
    name: 'auth.resetwithtoken',
    meta: {  name: 'Reset with token' },
    component: ResetToken
  }
]
const pageschildRoute = () =>[
  {
    path: 'error-404',
    name: 'error.404',
    meta: {  name: 'Error 404' },
    component: Error404
  },
  {
    path: 'error-500',
    name: 'error.500',
    meta: {  name: 'Error 500' },
    component: Error500
  },
  {
    path: 'pages-maintainance',
    name: 'pages.maintenance',
    meta: {  name: 'Maintaiance' },
    component: Maintainance
  },
]
const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    meta: { requiresAuth: true}
  },
  {
    path: '/order',
    name: '',
    component: LayoutSubMenu,
    meta: { requiresAuth: true},
    children: subMenuRequired()
  },
  {
    path: '/orders',
    name: 'orders',
    component: Layout1,
    meta: { requiresAuth: true},
    children: orders()
  },
  {
    path: '/allproducts',
    name: 'allproducts',
    component: Layout1,
    meta: { requiresAuth: true},
    children: products()
  },
  {
    path: '/products',
    name: 'product',
    component: ProductLayout,
    meta: { requiresAuth: true},
    children: productMenuRequired()
  },
  {
    path: '/auth',
    name: 'auth',
    component: BlankLayout,
    children: authchildRoute()
  },
  {
    path: '/pages',
    name: 'pages',
    component: BlankLayout,
    children: pageschildRoute()
  },
  {
    path: '*',
    name: 'notfound',
    component: Error404
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
  if(Cookies.get(ACCESS_TOKEN)) {
    await store.dispatch('getMe');
    if (store.state.auth.isAuthenticated) {
      next();
  } else {
    next({ name: 'auth.login' })
  }
} else{
  next({ name: 'auth.login' })
}
} else {
  next()
}
});

export default router
