import mutations from './mutations';
import { DataService } from '../../config/dataService';
import router from '../../router';
// import Cookies from 'js-cookie'


const state = () => ({
  isAuthenticated: false,
  loading: false,
  error: null,
  user: null,
  activeStoreView: null,
});

const getters = {
    getUser: state => state.user,
    getActiveStoreView: state => state.activeStoreView
  };

const actions = {
  async login({ commit }, { email, companyName, password }) {
    try {
      commit('loginBegin');
      const { data } = await DataService.post('auth/login', {
        email,
        company_name: companyName,
        password,
      });
      if (data.user.is_admin) {
        commit('loginSuccess', data);
        router.push({ name: 'omni-clients-list' });
        this.$toast.success('You’ll receive an email with your password reset.');
      } else {
        if (data.user.first_login === 1) {
          const {
            data: { status },
          } = await DataService.post(`auth/forgot-password`, {
            email,
          });

          if (status === 'success') {
            this.$toast.success('You’ll receive an email with your password reset.');
          }
        } else if (!data.user.mfa) {
          commit('loginSuccess', data);
          router.push({ name: 'allorders' });
        } else {
          commit('loginSuccess', data);
          router.push({ name: 'passcode', params: { id: data.user.id } });
        }
      }
    } catch {
      commit('setAuthErr');
    }
  },
  async requestReset({commit},{ email }) {
    try {
      commit('loginBegin');
      const { data } = await DataService.post('auth/forgot-password', {
        email
      });
      if (data.status === 'success') {
        router.push({ name: 'auth.login' });
      }
    } catch ({ data }) {
      //err
    }
  },
  async resetPassword({commit},{ token, email, password, confirm_password }) {
    try {
      commit('loginBegin');
      const { data } = await DataService.post(`auth/reset-password/${token}`, {
        email,
        password,
        confirm_password
      });
      if (data.status === 'success') {
        router.push({ name: 'auth.login' });
      }
    } catch ({ data }) {
      //err
    }
  },
  async loginWithPasscode({ commit }, { id, passcode }) {
    try {
      commit('loginBegin');
      const { data } = await DataService.post('auth/passcodeLogin', {
        id,
        passcode,
      });
      if (data.status === 'success') {
        commit('loginSuccess', data);
        router.push({ name: 'omni-orders-list' });
      }
    } catch ({ data }) {
      commit('setAuthErr', data.message);
      if (data.error === 'could_not_found_passcode' || data.error === 'passcode_expired') {
        router.push({ name: 'login' });
      }
    }
  },
  async logOut({ commit }) {
    try {
      commit('logoutBegin');
      await DataService.post('auth/logout');
      commit('logoutSuccess', null);
      router.push({ name: 'auth.login' });
    } catch (err) {
      commit('setAuthErr', err);
    }
  },
  async getMe({ commit }) {
    try {
      const { data } = await DataService.get('me');
      commit('setMe', data);

      // const activeStoreView = $cookies.get('active_store_view');
      // commit('setActiveStoreView', activeStoreView);
    } catch (err) {
      const { status } = err;
      if (status === 401) {
        router.push({ name: 'auth.login' });
      }
      commit('setAuthErr');
    }
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};