import { DataService } from '../../config/dataService';
export default {
  async fetchProductList({ commit, rootState }) {
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      const activeFilter = rootState.productFilters.availableFilters;
      const skuFilter = rootState.productFilters.availableFilters.findIndex(task => task.field === 'sku')
      let sku = [];


        if (activeFilter[skuFilter].isActive){
          sku = [{
            filters: [
              {
                field: activeFilter[skuFilter].field,
                value: activeFilter[skuFilter].filterValue,
                condition_type: 'like'
              },
            ],
          }];
        }
    
      let searchCriteria =
      {

        "pageSize": "24",
        "currentPage": "1",
        filterGroups: [
          {
            filters: [
              {
                field: 'store_id',
                value: activeStoreView.store_id,
              },
            ],
          },
          {
            filters: [
              {
                field: 'visibility',
                value: 1,
                condition_type: 'gt'
              },
            ],
          },
          {
            filters: [
              {
                field: 'is_epc',
                condition_type: 'null'
              },
            ],
          },
          ...sku
        ],
        sortOrders: [
          {
            field: 'created_at',
            direction: 'DESC',
          },
        ]

      };
      const {
        data: { data, status },
      } = await DataService.get(`${activeStoreView.code}/products`, { searchCriteria });
      if (status === 'success') {
        commit('setProductData', data);
      }
    } catch (err) {
      commit('setError', err);
    }
  },
  async fetchProduct({ commit, rootState }, { sku, disableLoading }) {
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      if (!disableLoading) commit('fetchBegin');
      const {
        data: { data, status },
      } = await DataService.get(`${activeStoreView.code}/products/${sku}?storeId=${activeStoreView.store_id}`);
      if (status === 'success') {
        commit('setProduct', data);
        commit('setCustomAttributes', data);
        commit('setNationalCodeFromProduct');
        const {
          data: { data: attributes, status },
        } = await DataService.get(
          `${activeStoreView.code}/products/attribute-sets/${data.attribute_set_id}/attributes`,
        );

        if (status === 'success') {
          commit('setAttributes', attributes);
        }

        if (data.type_id === 'configurable') {
          const childIds = data?.extension_attributes?.configurable_product_links;
          if (childIds) {
            const searchCriteria = {
              filterGroups: [
                {
                  filters: [
                    {
                      field: 'entity_id',
                      value: childIds.join(','),
                      condition_type: 'in',
                    },
                  ],
                },
              ],
            };

            const {
              data: { data, status },
            } = await DataService.get(`${activeStoreView.code}/products`, {
              searchCriteria,
            });

            if (status === 'success') {
              commit('setChildProducts', data.items);
            }
          }
        }
      }
      if (!disableLoading) commit('fetchEnd');
    } catch (err) {
      commit('setError', err);
    }
  },
  async saveProduct({ rootState }) {
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      let response = null;
        response = await DataService.put(`${activeStoreView.code}/products/${rootState.products.product.sku}`, rootState.products.product);
      if (response.data.status === 'success') {
        console.log('saved')
      }
    } catch (err) {
      console.log('setError', err);
    }
  },
  async updateProductMedia({ commit, state, rootState }, { files }) {
    const activeStoreView = rootState.auth.activeStoreView;
    try {
      await DataService.post(`${activeStoreView.code}/products/${state.product.sku}/media`, {
        files,
      });
      const {
        data: { data, status },
      } = await DataService.get(`${activeStoreView.code}/products/${state.product.sku}`);
      if (status === 'success') {
        commit('setProduct', data);
      }
    } catch (err) {
      commit('setError', err);
    }
  },
  async deleteMedia({ commit, rootState }, {sku, id }) {
    const activeStoreView = rootState.auth.activeStoreView;
    try {
      await DataService.delete(`${activeStoreView.code}/products/${sku}/media/${id}`);
    } catch (err) {
      commit('setError', err);
    }
  },
}
