import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  availableFilters: [
    {
      id: 1,
      name: 'Email',
      field: 'customer_email',
      operators: [
          'like',
          'eq'
      ],
      component: 'text',
      isMultiSelect: false,
      filterValue: '',
      isActive: false
    },
    {
      id: 2,
      name: 'Name',
      field: 'customer_firstname',
      operators: [
          'like',
          'eq'
      ],
      component: 'text',
      isMultiSelect: false,
      isActive: false
    },
    {
      id: 3,
      name: 'Status',
      field: 'status',
      operators: [
          'in',
          'nin'
      ],
      component: 'text',
      isMultiSelect: true,
      isActive: false
    }
  ]
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
