export default {
  setFilter (state, payload) {
    const taskIndex = state.availableFilters.findIndex(task => task.id === payload.id)
    state.availableFilters[taskIndex].isActive = payload.status
    state.availableFilters[taskIndex].filterValue = payload.filterValue
  },
  setInvoiceList (state, data) {
    state.invoices.push(data)
  },
  setInvoice (state, data) {
    state.invoice.push(data)
  }
}
