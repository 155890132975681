import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth/actionCreator';
import orders from './orders/actions';
import invoices from './invoices';
import orderFilters from './order-filters';
import productFilters from './product-filters';
import products from './products';
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    orders,
    invoices,
    orderFilters,
    productFilters,
    products
  },
  state: {
    appName: ' Omni Next',
    logo: require('../assets/images/logo.png'),
    darklogo:require('../assets/images/logo.png'),
    dark: true,
    namespaced: true,
    user:{
      name:'Bill Yerds',
      image:require('../assets/images/user/1.jpg'),
    }
  },
  mutations: {
    layoutModeCommit (state, payload) {
      state.dark = payload
      if (!payload) {
        state.logo = require('../assets/images/logo.png')
      } else {
        state.logo = require('../assets/images/logo.png')
      }
    }
  },
  actions: {
    layoutModeAction (context, payload) {
      context.commit('layoutModeCommit', payload.dark)
    }
  },
  getters: {
    appName: state => { return state.appName },
    logo: state => { return state.logo },
    darklogo: state => { return state.darklogo },
    image1: state => { return state.user.image },
    name: state => { return state.user.name },
    image2:state => { return state.user.image2},
    image3:state => { return state.user.image3},
    dark: state => { return state.dark },
  }
})
