import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
productList: [],
productDetails: {},
national_code: '',
attributeOptions: [],
product: {},
  childProducts: [],
  childProduct: {},
  attributes: [],
  custom_attributes: [],
  whitelist: [
    "description",
    "tax_class_id",
    "special_to_date",
    "enhanced_title"
  ]
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
